import React, { useRef, useState, Component, createRef } from 'react';
import logo from './logo.svg';
import './App.css';
import Navigation from './Navigation/Navigation';
import Header from './Header/Header';
import ServiceDesk from './ServiceDesk/ServiceDesk';
import Network from './Network/Network';
import InfrastructureServices from './InfrastructureServices/InfrastructureServices';
import ITSecurity from './ITSerucity/ITSecurity';
import BATeam from './BATeam/BATeam';
import AllServices from './AllServices/AllServices';
import DEVTeam from './DEVTeam/DEVTeam';
import QATeam from './QATeam/QATeam';
import PMOTeam from './PMOTeam/PMOTeam';
import { ReactComponent as ArrowBack } from './img/VectorArrowBack.svg';
import IAllServices from './Interfaces/IAllServices';
import { IAppProps, IAppState } from './Interfaces/IAppProps';
import ITeam from './Interfaces/ITeam';
import { userInfo } from 'os';
import data from './data/dataAbona.json';
import { Helper } from './data/Helper';
import reactStringReplace from 'react-string-replace';

class App extends Component<IAppProps, IAppState>{
  private teams: ITeam[] = [];
  private openRef: any;
  private testTeams: ITeam[] = [];
  private helper: Helper = new Helper();
  constructor(props: IAppProps) {
    super(props);
    this.openRef = React.createRef();
    this.state = {
      indexofTeam: 55,
      checkMainpage: false,
      showSearchButtonDiv: false,
      hideSearchElements: false,
      teams: this.teams,
      openRef: null,
      ItemName: null,
      CloseMainImage: false,
      OpenAllServices: true
    };
  }
  getTitle = (name: string) => {
    this.setState({ ItemName: name })
  }

  changeIndex = (index: any) => {
    this.setState({indexofTeam: index});
    
  }
  redirectToTeamFromLink = (link: string) => {
    if(link.includes("~") == true){
      link = link.substring(window.location.href.indexOf("#") + 1, window.location.href.indexOf("~"));
    }
    else{
      link = link.substring(window.location.href.indexOf("#") + 1, link.length);
    }
    console.log("Linka", link)

    switch (link) {
      case "corporate%20network%20(Network%20Team)":
        this.changeIndex(0)
        this.MainPageChange("corporate network (Network Team)")
        break;
      case "IP%20telephony,%20Videoconferences,%20Contact%20Centers%20(Network%20Team)":
        this.changeIndex(1)
        this.MainPageChange("IP telephony, Videoconferences, Contact Centers (Network Team)")
        break;
      case "Service%20Desk%20(L1,%20L2)":
        this.changeIndex(2)
        this.MainPageChange("Service Desk (L1, L2)")
        break;
      case "ERP%20|%20CRM%20systems%20|%20%20transport%20exchange":
        this.changeIndex(3)
        this.MainPageChange("ERP | CRM systems |  transport exchange")
        break;
      case "IT%20Security":
        this.changeIndex(4)
        this.MainPageChange("IT Security")
        break;
      case "BA%20team":
        this.changeIndex(5)
        this.MainPageChange("BA team")
        break;
      case "SharePoint":
        this.changeIndex(6)
        this.MainPageChange("SharePoint")
        break;
      case "DEV%20team":
        this.changeIndex(7)
        this.MainPageChange("DEV team")
        break;
      case "QA%20team":
        this.changeIndex(8)
        this.MainPageChange("QA team")
        break;
      case "PMO%20team":
        this.changeIndex(9)
        this.MainPageChange("PMO team")
        break;
      case "Search":
        this.changeIndex(60)
        this.MainPageChange("corporate network (Network Team)")
        this.showSearchButtonDiv();
        
        break;
      case "MainPage":
        this.changeIndex(55)
        this.MainPageChange("MainPage")
        break;
      default:
        this.changeIndex(55)
        this.MainPageChange("EmptyCall")
        break;
    }
  }

  async componentDidMount() {
    let teams = await this.helper.setTeamsInStateTest(this)
    console.log("TEAMS", teams);
    
    this.setState({ teams: teams })
    if (this.state.ItemName == null) {
      this.setState({ CloseMainImage: true })
    }
    
    await this.redirectToTeamFromLink(window.location.href); //DON`T MOVE IT! IT MUST BE AT THE END OF FUNC
  }
  // componentWillReceiveProps(nextProps: IAppProps) {
  //   //this.setState( {teams:this.teams});
  //   //this.setState( {teams:this.teams});
  // }

  showSearchButtonDiv = () => {
    if (this.state.showSearchButtonDiv == false) {
      this.setState({ showSearchButtonDiv: true });
    }
    else {
      this.setState({ showSearchButtonDiv: false });
    }

  }
  hideSearchElementsFunc = () => {
    if (this.state.hideSearchElements == false) {
      this.setState({ hideSearchElements: true });
    }
    else {
      this.setState({ hideSearchElements: false });
    }
  }
  MainPageChange = (element: any) => {
    if (element == 'EmptyCall') {
      this.setState({
        ItemName: null,
        checkMainpage: true,
      })
      this.changeIndex(50)
    }
    else if(element == 'MainPage') {
      this.setState({
        ItemName: null,
        checkMainpage: true,
      })
      this.changeIndex(50)
    }
    else {
      this.setState({
        ItemName: element,
      })
    }
    return null;
    
  }
  closeAllItemsTochoose = () => {
    this.setState({
      teams: this.teams,
      openRef: null,
      ItemName: null,
      CloseMainImage: false,
      OpenAllServices: true
    });
  }
  render() {
    let body = document.body;
    body.addEventListener('mousedown', () => {
      let items = document.querySelectorAll('aside');
      items.forEach((item) => {
        if (item.classList[0] == "wrapper-section-select-option-color") {
          item.classList.replace("wrapper-section-select-option-color", "wrapper-section-select-option");
        }
      })
    })
    return (<>
      <Navigation showSearchButtonDiv={this.state.showSearchButtonDiv} hideSearchElements={this.state.hideSearchElements} hideSearchElementsFunc={this.hideSearchElementsFunc} indexofTeam={this.state.indexofTeam} changeIndex={this.changeIndex} getTitle={this.getTitle} teams={this.state.teams} openRef={this.openRef} ItemName={this.state.ItemName} MainPageChange={this.MainPageChange} showSearchButton={this.showSearchButtonDiv} />
      {//this.state.ItemName == null &&
        <Header teams={this.state.teams} openRef={this.openRef} ItemName={this.state.ItemName} showSearchButton={this.state.showSearchButtonDiv} />}
      {//this.state.ItemName != null &&
        <AllServices showSearchButton={this.showSearchButtonDiv} hideSearchElements={this.state.hideSearchElements} hideSearchElementsFunc={this.hideSearchElementsFunc} showSearchButtonDiv={this.state.showSearchButtonDiv} ItemName={this.state.ItemName} teams={this.state.teams} MainPageChange={this.MainPageChange} closeAllItemsTochoose={this.closeAllItemsTochoose} />}
    </>
    );
  }
}
export default App;
