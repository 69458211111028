import IAllServices from "../Interfaces/IAllServices";
import ITeam from "../Interfaces/ITeam";
import ISubTeam from "../Interfaces/ISubTeam";
import IService from "../Interfaces/IService";
import settings from "../settings.json";
import axios from "axios";
export class Helper {
  public async GetTeams(_data: any[]): Promise<IAllServices> {
    //console.log("_data", _data);

    let result: IAllServices = {
      teams: [],
      ItemName: "",
      showSearchButtonDiv: false,
      hideSearchElements: false,
      hideSearchElementsFunc: () => { },
      showSearchButton: () => { },
      MainPageChange: () => { },
      closeAllItemsTochoose: () => { },
    };

    let prevSubTeam = "";
    let prevTeam = "";
    let teamsArray = [];
    let subTeamsArray = [];
    let servicesArray = [];
    for (let i = 0; i < _data.length; i++) {
      let curTeam = _data[i].Team;
      let curSubTeam = _data[i].SubTeam ?? "";
      if (curTeam != prevTeam) {
        subTeamsArray = [];
        servicesArray = [];
        servicesArray.push({
          isChecked: false,
          service_name: _data[i].Service,
          name: _data[i].Task_Type,
          Response_time: _data[i].Response_time,
          Resolution_time: _data[i].Resolution_time,
          SLA: _data[i].SLA,
          InEx: _data[i].Included_Proposal,
        });

        subTeamsArray.push({
          subTeam: curSubTeam,
          services: servicesArray,
        });

        teamsArray.push({
          Team: curTeam,
          subTeams: subTeamsArray,
        });
      } else {
        if (curSubTeam != prevSubTeam) {
          servicesArray = [
            {
              isChecked: false,
              service_name: _data[i].Service,
              name: _data[i].Task_Type,
              Response_time: _data[i].Response_time,
              Resolution_time: _data[i].Resolution_time,
              SLA: _data[i].SLA,
              InEx: _data[i].Included_Proposal,
            },
          ];
          teamsArray[teamsArray.length - 1].subTeams.push({
            subTeam: curSubTeam,
            services: servicesArray,
          });
        } else {
          teamsArray[teamsArray.length - 1].subTeams[
            teamsArray[teamsArray.length - 1].subTeams.length - 1
          ].services.push({
            isChecked: false,
            service_name: _data[i].Service,
            name: _data[i].Task_Type,
            Response_time: _data[i].Response_time,
            Resolution_time: _data[i].Resolution_time,
            SLA: _data[i].SLA,
            InEx: _data[i].Included_Proposal,
          });
        }
      }
      prevSubTeam = curSubTeam;
      prevTeam = curTeam;
    }

    let teams: ITeam[] = [];
    // additional grouping
    let grouppedTeams: any[] = [];
    teamsArray.map(team => {
      let teamExist = grouppedTeams.find(item => team.Team == item.Team);
      if (teamExist != undefined) {
        team.subTeams.map(s_team => {
          if (grouppedTeams[grouppedTeams.indexOf(teamExist)].subTeams.find((st: any) => st.subTeam == s_team.subTeam) != undefined) {
            s_team.services.map((service: any) => {
              grouppedTeams[grouppedTeams.indexOf(teamExist)].subTeams.find((st: any) => st.subTeam == s_team.subTeam).services.push(service);
            })
          } else {
            grouppedTeams[grouppedTeams.indexOf(teamExist)].subTeams.push(s_team);
          }
        });
      } else {
        grouppedTeams.push(team);
      }
    });
    teamsArray = grouppedTeams;
    console.log("----teamsArray", teamsArray);
    //
    teamsArray.forEach(teamMember => {
      //console.log(teamMember);
      let subTeams: ISubTeam[] = [];
      teamMember.subTeams.forEach((subTeam: any) => {
        //console.log(teamMember.Team, " - ", subTeam);
        let services: IService[] = [];
        let service_name_prev = "";
        subTeam.services.forEach((member: any) => {
          let service_name_cur = member.service_name;
          if (service_name_cur != service_name_prev) {
            services.push({
              name: service_name_cur,
              isAllSelected: false,
              options: [
                {
                  name: member.name,
                  Response_time: member.Response_time,
                  Resolution_time: member.Resolution_time,
                  SLA: member.SLA,
                  In_Ex: member.InEx,
                },
              ],
            });
          } else {
            services[services.length - 1].options.push({
              name: member.name,
              Response_time: member.Response_time,
              Resolution_time: member.Resolution_time,
              SLA: member.SLA,
              In_Ex: member.InEx,
            });
          }
          service_name_prev = service_name_cur;
        });

        subTeams.push({
          name: subTeam.subTeam,
          services: services,
        });

      });

      teams.push({
        hideSearchElements: false,
        hideSearchElementsFunc: () => { },
        showSearchButton: () => { },
        searchTextOnPage: () => { },
        showSearchButtonDiv: false,
        name: teamMember.Team,
        sub_team: subTeams,
        value: "",
        TeamName: "",
        getSelectedPositions: null,
        allNames: [],
        checkVisible: false,
        VariableToPassStateUpdate: false,
        searchResult: []
      });
    });
    // teams.map(t => result.teams.push(t))
    // const depts = teams.reduce((acc, current) => {
    //   acc.push(current)
    //   return acc
    //  }, [] as ITeam[])
    // console.log("depts", depts);
    // console.log("result.teams", result.teams);
    
    result.teams = teams;
    return result;
  }

  public async setTeamsInStateTest(parent: any): Promise<ITeam[]> {
    let teams: ITeam[] = [];
    var axios = require("axios");
    var config = {
      method: "get",
      url: "/api/AbonaServices/getAbonaServiceItem",
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then(async (response: { data: any }) => {
        //console.log(res);
        //console.log(res.data.d.results);
        let data_teams = [];
        for (let index = 0; index < response.data.length; index++) {
          const data_teams_el = {
            Team: response.data[index].title,
            SubTeam: response.data[index].field_1,
            Service: response.data[index].field_2,
            Service_Description: response.data[index].field_3,
            Task_Type: response.data[index].field_4,
            Response_time: response.data[index].field_5,
            Resolution_time: response.data[index].field_6,
            SLA: response.data[index].field_7,
            Included_Proposal: response.data[index].field_8,
            Price: response.data[index].field_9,
            Comments: response.data[index].field_10,
          };
          data_teams.push(data_teams_el);
        }
        if (data_teams.length > 0) {
          //parent.setState({teams:this.GetTeams(data_teams).teams});
          teams = (await this.GetTeams(data_teams)).teams;
        }
      })
      .catch(function () {
        //console.log(error);
      });
    console.log("teams", teams);
    return teams;
  }
  public setTeamsInState(parent: any): void {
    fetch("token.txt?v=" + new Date().toISOString())
      .then((r) => r.text())
      .then((token) => {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json;odata=verbose",
          },
        };
        axios
          .get(settings.list_api_url, config)
          .then(async (res) => {
            //console.log(res);
            //console.log(res.data.d.results);
            let data_teams = [];
            for (let index = 0; index < res.data.d.results.length; index++) {
              const element = res.data.d.results[index];
              const data_teams_el = {
                Team: element.Title,
                SubTeam: element.field_1,
                Service: element.field_2,
                Service_Description: element.field_3,
                Task_Type: element.field_4,
                Response_time: element.field_5,
                Resolution_time: element.field_6,
                SLA: element.field_7,
                Included_Proposal: element.field_8,
                Price: element.field_9,
                Comments: element.field_10,
              };
              data_teams.push(data_teams_el);
            }
            if (data_teams.length > 0) {
              //console.log("dateItem", data_teams);
              parent.setState({ teams: (await this.GetTeams(data_teams)).teams });
            }
          })
          .catch(function () {
            //console.log(error);
          });
      });
    /**/
  }
}
