import React, { useState, Component } from "react";
import './Team.css';
import { ReactComponent as FullLittleCircle } from '../img/FullLittleCircle.svg';
import Service from "../Service/Service";
import { AnyTxtRecord } from "dns";
import ITeam from "../Interfaces/ITeam";
import ISubTeam from "../Interfaces/ISubTeam";



class Team extends Component<ITeam, ITeam>{
    constructor(props: ITeam) {
        super(props);
        this.state = {
            searchTextOnPage: props.searchTextOnPage,
            showSearchButton: props.showSearchButton,
            hideSearchElements: props.hideSearchElements,
            hideSearchElementsFunc: props.hideSearchElementsFunc,
            searchResult: props.searchResult,
            showSearchButtonDiv: props.showSearchButtonDiv, 
            name: ((props.name == undefined) ? "" : props.name),
            sub_team: ((props.sub_team == undefined) ? [] : props.sub_team),
            value: props.value,
            TeamName: props.TeamName,
            getSelectedPositions: null,
            allNames: [],
            checkVisible: props.checkVisible,
            VariableToPassStateUpdate: props.VariableToPassStateUpdate
        };
    }
    componentDidMount() {



    }
    componentWillReceiveProps(nextProps: ITeam) {

        this.setState({ name: ((nextProps.name == undefined) ? "" : nextProps.name) });
        this.setState({ sub_team: ((nextProps.sub_team == undefined) ? [] : nextProps.sub_team) });

    }
    componentDidUpdate() {
        // console.log('props', this.props)
    }

    render() {
        const reactStringReplace = require('react-string-replace');
        let id_team = reactStringReplace(this.props.name, " ", () => "");
        return (<>
            <div style={{ marginTop: this.props.showSearchButtonDiv == true ? "105px" : "0px",  width: "100%", position: "absolute", left: "170", top: "700" }} id={id_team.join('')} className="wrapper-section-all-services">
                <div className="wrapper-header-all-services">
                    <div style={{ position: "relative" }}>
                        {
                            this.props.TeamName == null &&
                            <div style={{ position: "relative", top: "-225px", left: "-742px" }}>
                                <div className='circle' style={{ width: "163px", height: "163px", left: "666px", top: "134px" }}></div>
                                <div className='circle' style={{ width: "101px", height: "101px", left: "602px", top: "181px" }}></div>
                                {/* <FullLittleCircle style={{ position: "relative", left: "711px", top: "266px" }} /> */}
                            </div>
                        }
                    </div>

                    {/* <header id={id_team.join('')} className="all-services">
                    {this.state.name}
                </header> */}
                </div>
                <Service hideSearchElements={this.props.hideSearchElements} hideSearchElementsFunc={this.props.hideSearchElementsFunc} searchTextOnPage={this.props.searchTextOnPage} showSearchButton={this.props.showSearchButton} searchResult={this.props.searchResult} showSearchButtonDiv={this.props.showSearchButtonDiv} VariableToPassStateUpdate={this.props.VariableToPassStateUpdate} getSelectedPositions={this.props.getSelectedPositions} allNames={this.props.allNames} TeamName={this.props.TeamName} value={this.props.value} name={this.state.name} sub_team={this.state.sub_team} checkVisible={this.props.checkVisible}></Service>
            </div>
        </>)
    }
}

export default Team;
