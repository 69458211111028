import React, { useState, Component } from "react";
import './Service.css';
import { ReactComponent as FullLittleCircle } from '../img/FullLittleCircle.svg';
import Select from "../Select/Select";
import { AnyTxtRecord } from "dns";
import ISubTeam from "../Interfaces/ISubTeam";
import ITeam from "../Interfaces/ITeam";
import { threadId } from "worker_threads";
import reactStringReplace from "react-string-replace";
import $ from 'jquery';
import IService from "../Interfaces/IService";
import { padding } from "@mui/system";
import { throws } from "assert";
import { Checkbox } from '@mui/material';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
let serviceCount: number = 0;
interface ITeamState {
    name: string;
    sub_team: ISubTeam[];
    options: any[];
    selectedOptionName: string,
    selectedServiceName: string,
    selectedPositions: ITeamPositions[];
    isAllChecked: boolean;
    checkVisible: boolean;
    VariableToPassStateUpdate: boolean;
    checkSelectedService: boolean;
    checkDeafultService: boolean;
}
interface ITeamPositions {
    name: string;
    subTeam: ISubTeamPosition[];
}
interface ISubTeamPosition extends ISubTeam {
    teamName: string;
}
let counter = 0;
class Service extends React.Component<ITeam, ITeamState>{
    constructor(props: ITeam) {
        super(props);
        this.state = {
            name: ((props.name == undefined) ? "" : props.name),
            sub_team: ((props.sub_team == undefined) ? [] : props.sub_team),
            options: ((props.sub_team == undefined) ? [] : props.sub_team[0].services[0].options),
            selectedOptionName: "",
            selectedServiceName: props.sub_team[0].services[0].name + "~" + props.sub_team[0]?.name,
            checkSelectedService: false,
            checkDeafultService: false,
            selectedPositions: [],
            isAllChecked: false,
            checkVisible: this.props.checkVisible,
            VariableToPassStateUpdate: this.props.VariableToPassStateUpdate,
        };


    }

    public VariableToPassStateUpdate = this.props.VariableToPassStateUpdate
    onlySpaces(str: string) {
        return str?.trim().length === 0;
    }
    windowClick = (item: any) => {
        let items = document.querySelectorAll("tr")
        items.forEach(i => {
            if (i != null) {
                if (!this.onlySpaces(item)) {
                    if (i.id == item) {
                        i.className = ''
                    }
                }
            }
        })
        this.setState({ selectedOptionName: "" })
    }
    componentDidMount() {
        document.addEventListener("mousedown", () => { this.windowClick(this.state.selectedOptionName) });
    }
    componentWillUnmount() {
        //document.removeEventListener("mousedown", ()=>{this.windowClick(this.state.selectedOptionName)});
    }

    componentWillReceiveProps(nextProps: ITeam) {

        this.setState({ name: ((nextProps.name == undefined) ? "" : nextProps.name) });
        this.setState({ sub_team: ((nextProps.sub_team == undefined) ? [] : nextProps.sub_team) });

    }
    componentDidUpdate(prevProps: ITeam, prevState: ITeamState) {
        if (this.props.VariableToPassStateUpdate) {
            this.VariableToPassStateUpdate = true
        }
        if (!this.props.VariableToPassStateUpdate) {
            this.VariableToPassStateUpdate = false
        }
        if (prevState.sub_team !== this.state.sub_team) {
            //console.log("0]?")
            if (!this.onlySpaces(this.props.value)) {
                //   console.log("selectedOption[0]?", selectedOption)
                let AllOptions = this.state.sub_team.map(i => i.services.filter(j => j.options.some((o: any) => o.name == this.props.value)).map(a => a.options).flat()).flat();
                let selectedOption = this.state.sub_team.map(i => i.services.map(service => service.options.filter(o => o.name == this.props.value).flat()).flat()).flat()
                let selectedService = this.state.sub_team.map(i => i.services.filter(j => j.options.some((o: any) => o.name == this.props.value)).flat().flat()).flat();
                if (AllOptions.length == 0) {
                    if (selectedOption[0]?.name != "") {

                        this.setState({ options: this.state.sub_team[0].services[0].options, isAllChecked: this.state.sub_team[0].services[0].isAllSelected, selectedOptionName: selectedOption[0]?.name, selectedServiceName: selectedService[0]?.name + "~" + this.state.sub_team[0].name })
                    }
                }
                else {
                    if (selectedOption[0]?.name != "") {
                        //console.log("selectedOption[0]?.name")
                        this.setState({ options: AllOptions, isAllChecked: selectedService[0].isAllSelected, selectedOptionName: selectedOption[0]?.name, selectedServiceName: selectedService[0]?.name + "~" + this.state.sub_team[0].name  })
                    }
                }
                document.addEventListener("mousedown", () => { this.windowClick(selectedOption[0]?.name) });
            }
            else {
                // console.log("selectedOption[1]?.name")
                if (this.state.sub_team[0].services[0].isAllSelected) {
                    this.state.sub_team[0].services[0].options.forEach(i => {
                        i.isChecked = this.state.sub_team[0].services[0].isAllSelected
                    })
                }
                this.setState({ options: this.state.sub_team[0].services[0].options, isAllChecked: this.state.sub_team[0].services[0].isAllSelected, selectedServiceName: this.state.sub_team[0].services[0].name + "~" + this.state.sub_team[0].name })
            }

        }
        if (prevProps.value != this.props.value) {
            // console.log("0]?")
            let AllOptions = this.state.sub_team.map(i => i.services.filter(j => j.options.some((o: any) => o.name == this.props.value)).map(a => a.options).flat()).flat();
            let selectedOption = this.state.sub_team.map(i => i.services.map(service => service.options.filter(o => o.name == this.props.value).flat()).flat()).flat()
            let selectedService = this.state.sub_team.map(i => i.services.filter(j => j.options.some((o: any) => o.name == this.props.value)).flat().flat()).flat();
            if (AllOptions.length != 0 && selectedOption.length != 0 && selectedService.length != 0) {
                if (selectedOption[0]?.name != "") {
                    this.setState({ options: AllOptions, isAllChecked: selectedService[0].isAllSelected, selectedOptionName: selectedOption[0]?.name, selectedServiceName: selectedService[0]?.name })
                }
                document.addEventListener("mousedown", () => { this.windowClick(selectedOption[0]?.name) });
            }

        }
        if (prevState.isAllChecked != this.state.isAllChecked) {
            counter = 0;
        }
    }

    public checkAll = () => {
        // console.log('visov')
        let isAllChecked = !this.state.isAllChecked;
        let AllCheckedSubTeam = [...this.state.sub_team]
        AllCheckedSubTeam.forEach(i => {
            i.services.forEach(s => {
                if (s.name + "~" + i.name == this.state.selectedServiceName) {
                    s.isAllSelected = isAllChecked
                }
            })
        });
        //await this.setState({sub_team:AllCheckedSubTeam})
        let options = [...this.state.options];
        options.forEach(i => {
            i.isChecked = isAllChecked
        })
        this.setState({ options: options, isAllChecked: isAllChecked })
        let selectedPositions = []
        if (this.props.TeamName == null) {
            let selectedSubTeam = this.state.sub_team.filter(i => i.services.some(s => s.name + "~" + i.name == this.state.selectedServiceName));
            let selectedService = selectedSubTeam.map(i => i.services.filter(s => s.name + "~" + i.name == this.state.selectedServiceName).flat().flat()).flat()
            let subTeam: ISubTeamPosition = {
                name: selectedSubTeam[0].name,
                services: selectedService,
                teamName: this.props.TeamName,
            }
            let subTeams: ISubTeamPosition[] = [];
            subTeams.push(subTeam);
            let team: ITeamPositions = {
                name: this.props.allNames[0],
                subTeam: subTeams
            }
            selectedPositions.push(team)
        }
        else {
            let selectedSubTeam = this.state.sub_team.filter(i => i.services.some(s => s.name + "~" + i.name == this.state.selectedServiceName));
            let selectedService = selectedSubTeam.map(i => i.services.filter(s => s.name + "~" + i.name == this.state.selectedServiceName).flat().flat()).flat()
            let subTeam: ISubTeamPosition = {
                name: selectedSubTeam[0].name,
                services: selectedService,
                teamName: this.props.TeamName,
            }
            let subTeams: ISubTeamPosition[] = [];
            subTeams.push(subTeam);
            let team: ITeamPositions = {
                name: this.props.TeamName,
                subTeam: subTeams
            }
            selectedPositions.push(team)

        }
        this.props.getSelectedPositions(selectedPositions, isAllChecked)
    }
    public checkCurrent = (item: any) => {
        let options = [...this.state.options];
        let index = options.findIndex((i) => i == item);
        // console.log('index', index)
        // console.log('options', options)
        options[index].isChecked = !options[index].isChecked
        let isAllChecked = options.filter(i => i.isChecked == false).length > 0
        let AllCheckedSubTeam = [...this.state.sub_team]
        if (isAllChecked) {
            AllCheckedSubTeam.forEach(i => {
                i.services.forEach(s => {
                    if (s.name + "~" + i.name == this.state.selectedServiceName) {
                        s.isAllSelected = false
                    }
                })
            });
            this.setState({ isAllChecked: false })
        }
        // console.log(options)
        this.setState({ options: options })
        let selectedPositions = []
        if (this.props.TeamName == null) {
            let selectedSubTeam = this.state.sub_team.filter(i => i.services.some(s => s.name + "~" + i.name == this.state.selectedServiceName));
            let selectedService = selectedSubTeam.map(i => i.services.filter(s => s.name + "~" + i.name == this.state.selectedServiceName).flat().flat()).flat()
            let selectedOption = selectedService.map(i => i.options.filter(o => o.name == item.name).flat().flat()).flat()
            let service: IService = {
                name: selectedService[0].name,
                options: selectedOption,
                isAllSelected: selectedService[0].isAllSelected
            }
            let services: IService[] = [];
            services.push(service)
            let subTeam: ISubTeamPosition = {
                name: selectedSubTeam[0].name,
                services: services,
                teamName: this.props.allNames[0],
            }
            let subTeams: ISubTeamPosition[] = [];
            subTeams.push(subTeam);
            let team: ITeamPositions = {
                name: this.props.allNames[0],
                subTeam: subTeams
            }
            selectedPositions.push(team)
        }
        else {
            let selectedSubTeam = this.state.sub_team.filter(i => i.services.some(s => s.name + "~" + i.name == this.state.selectedServiceName));
            let selectedService = selectedSubTeam.map(i => i.services.filter(s => s.name + "~" + i.name == this.state.selectedServiceName).flat().flat()).flat();
            let selectedOption = selectedService.map(i => i.options.filter(o => o.name == item.name).flat().flat()).flat();
            let service: IService = {
                name: selectedService[0].name,
                options: selectedOption,
                isAllSelected: selectedService[0].isAllSelected
            }
            let services: IService[] = [];
            services.push(service)
            let subTeam: ISubTeamPosition = {
                name: selectedSubTeam[0].name,
                services: services,
                teamName: this.props.TeamName,
            }
            let subTeams: ISubTeamPosition[] = [];
            subTeams.push(subTeam);
            let team: ITeamPositions = {
                name: this.props.TeamName,
                subTeam: subTeams
            }
            selectedPositions.push(team)
        }
        this.props.getSelectedPositions(selectedPositions)
    }
    // handleClickShow=()=>{
    //     if(this.state.checkVisible){
    //         this.setState({checkVisible: false})
    //          this.setState({isAllChecked: false})
    //     }
    //     else{
    //         this.setState({checkVisible: true})
    //     }
    // }
    public async handleCheck(e: any, subteam: string) {

        if (this.state.selectedServiceName != "") {
            let element;
            if (this.state.selectedServiceName == "SharePoint") {
                element = document.getElementById("sharePoint")
            }
            else {
                element = document.getElementById(this.state.selectedServiceName)
            }
            if (element != null) {
                element.className = "serviceNameULScrollLi"
            }
        }
        let items = document.querySelectorAll("li")
        items.forEach(i => {

            if (i != null) {
                if (i.getAttribute("value") == e) {
                    i.className = "serviceNameULScrollLiSelected"
                }
            }
        })
        let AllCheckedSubTeam = [...this.state.sub_team]
        AllCheckedSubTeam.forEach(i => {
            i.services.forEach(s => {
                if (s.name == this.state.selectedServiceName) {
                    s.isAllSelected = this.state.isAllChecked
                }
            })
        });
        this.setState({ selectedServiceName: e + "~" + subteam })
        let services = this.state.sub_team.filter(s_team => s_team.name == subteam).map(i => i.services.filter(service => service.name == e).flat())    
        console.log("services", services)
        
        for (var i = 0; i < services.length; i++) {
            if (services[i].length != 0) {

                this.setState({ isAllChecked: services[i][0].isAllSelected })
            }
        }
        let options = []
        for (var i = 0; i < services.length; i++) {
            if (services[i].length != 0) {
                options = services[i][0].options
            }
        }
        for (var a = 0; a < services.length; a++) {
            if (services[a].length != 0) {
                if (services[a][0]?.isAllSelected) {
                    options.forEach(i => {

                        i.isChecked = services[a][0].isAllSelected

                    })
                }
            }
        }
        this.setState({ options: options })

        this.forceUpdate();
    }
    render() {
        let checkBool = false;
        return (<>
            <div className="view">
                {(this.state.name == "") ? <></> : <div className="">
                    {
                        this.props.hideSearchElements == false ?
                            <header className="all-services">{this.state.name.substring(0, 1).toUpperCase() + this.state.name.substring(1)}</header>
                            :
                            <></>
                    }
                </div>
                }
                <div className="service">
                    {

                        this.props.hideSearchElements == false ?
                            <div className="serviceName">
                                <ul className="serviceNameULScroll">
                                    {this.state.sub_team.map((services, key) => {
                                        return (
                                            <>
                                                {services.name != "" && <li style={{ background: "#666666" }} className="header"> {services.name == "Обмен сообщениями (мессенджеры)" ? "Messaging" : services.name}</li>}
                                                {
                                                    services.services.map((service) => {
                                                        let hrefLocation = window.location.href; //Get current URL
                                                        hrefLocation = hrefLocation.substring(hrefLocation.indexOf('#')); //Remove name of website
                                                        hrefLocation += "~"
                                                        hrefLocation = hrefLocation.slice(0, hrefLocation.indexOf('~')); //Remove name of servise
                                                        let DecodeURI = decodeURI(window.location.href.substring(window.location.href.length, window.location.href.indexOf('~') + 1)); //Decode data from URL
                                                        let subTeam: string = services.name;
                                                        
                                                        
                                                        if (this.state.selectedServiceName?.length <= 1 || this.state.selectedServiceName == undefined) {
                                                            if (!checkBool) {
                                                                this.setState({ selectedServiceName: service.name + "~" + subTeam})
                                                                checkBool = true;
                                                            }
                                                            
                                                            if (service.name == this.props.value) {
                                                                this.setState({ selectedServiceName: this.props.value + "~" + subTeam})
                                                                this.handleCheck(service.name, subTeam);
                                                            }
                                                        }
                                                        if (!this.state.checkSelectedService && DecodeURI.toLowerCase() == service.name.toLowerCase()) { //If for reload page
                                                            this.setState({ selectedServiceName: service.name + "~" + subTeam, checkSelectedService: true })
                                                            this.handleCheck(service.name, subTeam);
                                                        }
                                                        return (
                                                            this.state.selectedServiceName == service.name + "~" + subTeam ?
                                                                <a className="linkServices" href={hrefLocation + "~" + service.name + "~" + subTeam}>
                                                                    <li id={service.name == "SharePoint" ? "sharePoint" : service.name + "~" + subTeam} onClick={() => this.handleCheck(service.name, subTeam)} value={service.name + "~" + subTeam} className="serviceNameULScrollLiSelected">{service.name.substring(0, 1).toUpperCase() + service.name.substring(1)}</li>
                                                                </a>
                                                                :
                                                                <a className="linkServices" href={hrefLocation + "~" + service.name + "~" + subTeam}>
                                                                    <li onClick={() => this.handleCheck(service.name, subTeam)} value={service.name + "~" + subTeam} className="serviceNameULScrollLi">{service.name.substring(0, 1).toUpperCase() + service.name.substring(1)}</li>
                                                                </a>
                                                        )
                                                    })
                                                }
                                            </>
                                        )
                                    })}
                                </ul>
                            </div>
                            :
                            <></>
                    }
                    <div className="serviceOptions">
                        <table className="optionsTable">
                            <thead style={{ position: "sticky", top: "0", zIndex: "1" }}>
                                <tr style={{ height: "20px" }}>
                                    <th style={{ width: "3%", textAlign: "left" }}>

                                        {/* <Checkbox
                                                {...label}
                                                style={{ marginLeft: "2px", width: '30px', height: '30px', visibility: this.props.checkVisible ? "hidden" : 'visible' }}
                                                checked={this.state.isAllChecked}
                                                id="checkAll" className="checkboxMy"
                                                onChange={this.checkAll}
                                                defaultChecked
                                                sx={{
                                                    color: "#333",
                                                    '&.Mui-checked': {
                                                        color: "#333",
                                                    },
                                                }}
                                            /> */}
                                        {this.props.hideSearchElements ?
                                            <></>
                                            :
                                            <label className="container">
                                                <input style={{ marginLeft: "5px", width: '20px', height: '20px', marginTop: '0px' }} hidden={this.props.checkVisible} checked={this.state.isAllChecked} onClick={this.checkAll} type="checkbox" id="checkAll" className="checkboxMy" />
                                                <span className="checkmark" style={{ visibility: this.props.checkVisible ? "hidden" : 'visible', marginLeft: '1px' }}></span>
                                                <span className="geekmark"></span>
                                            </label>
                                        }
                                    </th>
                                    {
                                        this.props.hideSearchElements ?
                                            <></>
                                            :
                                            <>
                                                <th style={{ width: "39%", paddingTop: '10px', paddingBottom: '10px' }}></th>
                                                <th style={{ width: "78pt", color: 'white', fontWeight: 300 }}>Reaction time</th>
                                                <th style={{ width: "71pt", color: 'white', fontWeight: 300 }} ><span>Solution time   </span>
                                                    {this.state.selectedServiceName == "ABONA Project Management " ? //Must be space at the end in ABONA Project Management 
                                                        <Popup trigger={<img style={{ width: "14%", marginBottom: "-3.7px", marginLeft: "1px" }} src={require('../img/InfoIcon.svg').default} alt="icon" />}
                                                            position="bottom center">
                                                            <span className="infoIconText">depending on the complexity of the project</span>
                                                        </Popup>
                                                        : ""}
                                                </th>
                                                <th style={{ width: "72pt", color: 'white', fontWeight: 300 }}>SLA</th>
                                                <th style={{ width: "46pt", color: 'white', fontWeight: 300 }}>In|Ex</th>
                                            </>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.hideSearchElements == true && this.searchResult()}
                                {this.props.hideSearchElements == false && this.mapItems()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }
    searchResult() {
        let id_team = reactStringReplace(this.props.TeamName, " ", () => "");
        return this.props.searchResult.map(i => {
            return (<>
                <tr className="selectedOption" data-parentID={id_team.join('')} id={i.name}>

                </tr>
                <tr data-parentID={id_team.join('')} id={i}>
                    {/* <td className="changewidthtable">
                        <label className="container">
                            <input hidden={this.props.checkVisible} onClick={() => this.checkCurrent(i)} checked={i.isChecked} style={{ width: "20px", height: "20px" }} id={i.name + "checkbox"} type="checkbox" />
                            <span className="checkmark" style={{ visibility: this.props.checkVisible ? "hidden" : 'visible' }}></span>
                        </label>
                    </td> */}
                    <td onClick={(e) => { this.RedirectToItem(i) }}>{i}</td>
                </tr>
            </>
            )
        })
    }
    RedirectToItem(name: any) {
        // this.props.showSearchButton();
        this.props.hideSearchElementsFunc();
        this.props.searchTextOnPage(name);
    }
    mapItems() {
        let count = this.state.options.length - 1
        let id_team = reactStringReplace(this.props.name, " ", () => "");

        return this.state.options.map((i, key) => {
            if (this.VariableToPassStateUpdate) {
                i.isChecked = false
                //this.setState({ isAllChecked: false })
            }
            if (key === count) {
                this.VariableToPassStateUpdate = false
            }
            if (this.VariableToPassStateUpdate) {
                counter++
                if (counter == 1) {
                    this.setState({ isAllChecked: false })
                    for (let i = 0; i < this.state.sub_team[0].services.length; i++) {
                        this.state.sub_team[0].services[i].isAllSelected = false
                    }
                    //this.checkAll()
                    console.log('this.state.sub_team', this.state.sub_team)
                }
            }

            return (<>
                {this.state.selectedOptionName == i.name && this.state.selectedOptionName.length != 0 ?
                    <>
                        <tr className="selectedOption" data-parentID={id_team.join('')} id={i.name}>
                            <td>
                                {/* <label className="main">
                                    <input onClick={() => this.checkCurrent(i)} checked={i.isChecked} style={{ width: "15px", height: "15px" }} id={i.name + "checkbox"} type="checkbox" />
                                    <span className="geekmark"></span>
                                </label> */}
                            </td>
                            <td>{i.name}</td>
                            <td>{i.Response_time}</td>
                            <td>{i.Resolution_time}</td>
                            <td>{i.SLA}</td>
                            <td>{i.In_Ex}</td>
                        </tr>
                    </>
                    :
                    i.name.length != 0 ?  //Check on empty fields 
                        <tr data-parentID={id_team.join('')} id={i.name}>
                            <td className="changewidthtable">
                                {/* <Checkbox
                                {...label}
                                onClick={() => this.checkCurrent(i)}
                                checked={i.isChecked}
                                style={{ marginLeft: '0px', width: "30px", height: "30px", visibility: this.props.checkVisible ? "hidden" : 'visible' }}
                                id={i.name + "checkbox"}
                                //defaultChecked
                                sx={{
                                    color: "#333",
                                    '&.Mui-checked': {
                                        color: "#333",
                                    },
                                }}
                            /> */}
                                <label className="container">
                                    <input hidden={this.props.checkVisible} onClick={() => this.checkCurrent(i)} checked={i.isChecked} style={{ width: "20px", height: "20px" }} id={i.name + "checkbox"} type="checkbox" />
                                    <span className="checkmark" style={{ visibility: this.props.checkVisible ? "hidden" : 'visible' }}></span>
                                </label>
                            </td>
                            <td>{i.name.substring(0, 1).toUpperCase() + i.name.substring(1)}</td>
                            <td>{this.state.selectedServiceName == "ABONA Project Management " ? "on demand" : i.Response_time}</td>
                            <td>{i.Resolution_time}</td>
                            <td>{i.SLA}</td>
                            <td>{i.In_Ex}</td>
                        </tr>
                        :
                        null
                }
            </>
            )
        })
    }
}

export default Service;
